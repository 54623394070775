body{
    background: #fff;
}

// .ant{
//     &-btn{
//         border-radius: 2px;
//     }
//     &-card,
//     &-avatar{
//         border-radius: 8px;
//     }
// }

// .ant-btn{
//     height: 40px;
//     &-sm{
//         height: 30px;
//     }
// }

// a{
//     &.ant-btn{
//         line-height: 38px;
//     }
// }

// .ant-menu-horizontal{
//     border: 0;
// }

.cursor-pointer{
    cursor: pointer;
}

.bg-info{
    background-color: #E6F7FF !important;
    background: #E6F7FF !important;
}


.btn-drawer{
    height: 4em !important;
    width: 1.2em !important;
    -webkit-clip-path: polygon(100% 0%, 0 20%, 0 100%, 0 80%, 100% 100%);
    clip-path: polygon(100% 0%, 0 20%, 0 100%, 0 80%, 100% 100%);
}


.ant-badge-count{
    background: rgb(24, 144, 255);
}

.ant-pro-base-menu-item{
    &-text,
    &-icon{
        color: #083f7e;
    }

    &-icon{
        display: none;
        margin-left: 0;
        // width: 28px !important;
        // height: 28px !important;
        // font-size: 28px !important;
    }
}

.ant-menu-item{
    padding-left: 16px !important;
    // font-size: 14px;
    line-height: 18px;
    & .anticon{
        font-size: 18px;
        min-width: 18px;
        margin-right: 8px;
    }
    &-disabled{
        & .ant-pro-base-menu-item-text{
            color: #999999 !important;
        }
    }
}

.ant-menu-submenu-title .anticon{
    font-size: 28px !important;
    color: #083f7e;
}

.ant-menu-submenu-arrow:after{
    color: #083f7e;
}