/* @import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@500..600&display=swap'); */
body, * {
  font-family: 'Lexend', Arial, Helvetica, sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 500;
  /* letter-spacing: .3px; */
}

.ant-form-item.ant-form-label-lg > .ant-form-item-row > .ant-form-item-label > label {
  font-size: 1.1em;
  font-weight: bold;
  line-height: 3em;
}

.shadow-right,
.ant-layout-sider{
  box-shadow: 0 0 6px 0px #00000046;
  clip-path: inset(0px -6px 0px 0px);
}

.ant-pro-page-container-warp{
  box-shadow: 0 0 0 0 !important;
}

.ant-pro-page-container-warp-page-header{
  padding-block-end: 0px !important;
}

.text-right{
  text-align: right !important;
}

.text-code{
  font-family: monospace !important;
}

.shadow {
  box-shadow: 0.375rem !important;
}

.rounded {
  border-radius: 0.25rem !important;
}
.row{
  margin-top: 10px !important;
}
.required-label {
  position: relative; 
}

.required-asterisk {
  color: red; 
  margin-left: 4px; 
}

.ant-pro-base-menu.ant-pro-base-menu-collapsed .ant-menu-item, .ant-pro-base-menu.ant-pro-base-menu-collapsed .ant-menu-item-group>.ant-menu-item-group-list>.ant-menu-item, .ant-pro-base-menu.ant-pro-base-menu-collapsed .ant-menu-item-group>.ant-menu-item-group-list>.ant-menu-submenu>.ant-menu-submenu-title, .ant-pro-base-menu.ant-pro-base-menu-collapsed .ant-menu-submenu>.ant-menu-submenu-title {
  margin-left: -7px;
  width: 42px;
}